import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Platform } from '@core/data/model/platform';
import { Oauth2IntegrationInit } from '@core/data/model/oauth2-integration-init';
import { Oauth2IntegrationInitDto } from '@core/data/model/dto/oauth2-integration-init.dto';
import { OrganizationPlatform } from '@core/data/model/organization-platform';
import { PlatformConnectionDto } from '@core/data/model/dto/platform-connection.dto';
import { PlatformType } from '../type/platform.type';
import { OrganizationPlatformPatchDto } from '@core/data/model/dto/organization-platform-patch.dto';
import { BaseHttpService } from '@core/services/http/base-http.service';
import { Page } from '@core/data/model/page';
import { OrganizationPlatformResource } from '@core/data/model/organization-platform-resource';
import { PlatformResourceType } from '@core/data/type/platform-resource.type';
import { PlatformResourceFilter } from '@core/data/model/dto/platform-resource-filter';
import { PlatformResourceLinkDto } from '@core/data/model/dto/platform-resource-link-dto';
import { PlatformResourcePatchDto } from '@core/data/model/dto/platform-resource-patch.dto';
import { PlatformResourceConnectionPatchDto } from '@core/data/model/dto/platform-resource-connection-patch.dto';

@Injectable()
export class IntegrationService extends BaseHttpService {

  constructor(protected override httpClient: HttpClient) {
    super('integration-service', httpClient);
  }

  initOauth2Integration(platform: Platform, importResourceAutomatically = false): Observable<Oauth2IntegrationInit> {
    return this.post<Oauth2IntegrationInit>(
      '/integrations/init-oauth2',
      new Oauth2IntegrationInitDto(
        platform.type,
        importResourceAutomatically
      ));
  }

  updatePlatformResourceConnection(resourceId: string, connect: boolean): Observable<any> {
    return this.patch<OrganizationPlatformResource>(`/platform-webhooks/resources/${resourceId}`, new PlatformResourceConnectionPatchDto(connect));
  }

  findAllPlatforms(): Observable<Platform[]> {
    return this.get<Platform[]>('/integrations/platforms');
  }

  findAllOrganizationPlatforms(): Observable<OrganizationPlatform[]> {
    return this.get<OrganizationPlatform[]>('/integrations');
  }

  createConnection(platformType: PlatformType, platformConnection: PlatformConnectionDto): Observable<OrganizationPlatform> {
    return this.post<OrganizationPlatform>(`/integrations/${platformType}/connections`, platformConnection);
  }

  disconnectConnection(platform: Platform): Observable<OrganizationPlatform> {
    return this.delete<OrganizationPlatform>(`/integrations/${platform.type}/connections`);
  }

  patchOrganizationPlatform(platformType: PlatformType, patch: OrganizationPlatformPatchDto): Observable<OrganizationPlatform> {
    return this.patch<OrganizationPlatform>(`/integrations/${platformType}`, patch);
  }

  findAllPlatformResources(
    platformType: PlatformType,
    includeLinks = false,
    page = 0,
    size = 30,
    sortKey = 'name',
    sortDir = 'asc'
  ): Observable<Page<OrganizationPlatformResource>> {
    return this.get<Page<OrganizationPlatformResource>>(`/platform-resources/${platformType}?page=${page}&size=${size}&sort=${sortKey},${sortDir}&includeLinks=${includeLinks}`);
  }

  searchPlatformResources(
    platformResourceTypes?: PlatformResourceType[],
    includeLinks = false,
    sortKey = 'name',
    sortDir = 'asc'
  ): Observable<OrganizationPlatformResource[]> {
    const filter: PlatformResourceFilter = {
      platformResourceTypes: platformResourceTypes,
      includeLinks: includeLinks
    };
    return this.post<OrganizationPlatformResource[]>(`/platform-resources/search?sort=${sortKey},${sortDir}`, filter);
  }

  linkPlatformResources(source: OrganizationPlatformResource, targets: OrganizationPlatformResource[]): Observable<OrganizationPlatformResource> {
    const dto = new PlatformResourceLinkDto(targets.map(target => target.id));
    return this.post<OrganizationPlatformResource>(`/platform-resources/${source.id}/links`, dto);
  }

  patchPlatformResource(source: OrganizationPlatformResource, dto: PlatformResourcePatchDto): Observable<OrganizationPlatformResource> {
    return this.patch<OrganizationPlatformResource>(`/platform-resources/${source.id}`, dto);
  }
}
